import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	dialogPaper: {
		minWidth: '50vw'
	},
	large: {
		width: theme.spacing(15),
		height: theme.spacing(15),
		marginBottom: 20
	},
	withMobileDialog: 'xs'
});

const logo = '/static/images/Asistensi_logotipo.svg';

class ModalDisclaimer extends Component {
	render() {
		const { fullScreen, classes, handleClose, open, width } = this.props;
		return (
			<Dialog
				open={open}
				maxWidth="sm"
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={width < 600 ? true : false}
				withMobileDialog="sm"
			>
				<style global>
					{`
            .MuiPaper-rounded {
              border-radius: 25px;
            }
            .MuiDialogContent-root {
              overflow-y: hidden;
            }
          `}
				</style>

				<DialogTitle>
					<div className="flex items-center justify-center">
						<img
							alt="Logo Asistensi"
							src={logo}
							className="w-auto"
							style={{ height: 50, marginTop: 10, marginBottom: 10 }}
						/>
					</div>
					<div className="flex items-center justify-center">
						<p>
							<span className="font-poppins font-bold text-sm md:text-2xl text-purple-90">
								To the entire{' '}
							</span>
							<span className="font-poppins font-bold text-sm md:text-2xl text-pink-500">
								{' '}
								asistensi{' '}
							</span>
							<span className="font-poppins font-bold text-sm md:text-2xl text-purple-90">
								comunity
							</span>
						</p>
					</div>
					<div className="flex items-center justify-center">
						<span
							style={{
								color: '#200944'
							}}
							className="font-poppins font-bold text-xs text-center"
						>
							Company restructuring and organizational changes
						</span>
					</div>
				</DialogTitle>
				<DialogContent>
					<div
						style={{
							boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.03)'
						}}
						className="box-border overflow-y-scroll mx-2 h-full md:h-48 p-6 rounded-2xl mb-8 bg-gray-250 text-xs"
					>
						<p className="font-poppins font-normal">
							We address the entire{' '}
							<span className="text-pink-500 font-bold">asistensi</span>{' '}
							community to inform you about a business change taking place in
							our organization. As is publicly known, financial markets have
							been experiencing great instability in recent months and, like
							many other companies, we depend heavily on capital investment
							funds to operate. This situation has led us to the need to
							restructure our organization.
						</p>
						<p className="mt-4 font-poppins font-normal">
							In the case of Central America (Guatemala and Honduras), the
							Philippines, and Mexico, this change implies that we will not be
							offering new health plans. However, from an operational
							standpoint, we will continue to work with each of the affiliates
							in these countries and provide our services regularly, honoring
							our obligations to clients and suppliers. Regarding the Dominican
							Republic and Venezuela, we will continue to operate normally,
							guided by our purpose of making healthcare accessible to everyone.
						</p>
						<p className="mt-4 font-poppins font-normal">
							As an organization, we reiterate our commitment to continue
							working towards transforming the healthcare system and generating
							a positive impact on our society. Our goal remains. What changes
							is the way we organize and approach this important priority.
						</p>
						<p className="mt-4 font-poppins font-normal pb-6">
							We appreciate your understanding and support during this process
							of business change. We will continue to contribute to the
							well-being of society within our scope of action.
						</p>
					</div>
				</DialogContent>
				<DialogActions>
					<button>
						<span
							style={{
								position: 'absolute',
								top: 10,
								right: 25,
								color: '#FF2252',
								fontSize: 18
							}}
							className="font-poppins"
							onClick={handleClose}
						>
							X
						</span>
					</button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withMobileDialog({ breakpoint: 'xs' })(
	withStyles(styles)(ModalDisclaimer)
);
