import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import ModalDisclaimer from '../ModalDisclaimer';
import useWindowSize from '../../hooks/useWindowSize';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const bgHeader = `${serverUrlImages}/PH/home/asistensi-accessible-health-plans.jpg`;
const sillhoutte = '/images/header-silhouette.svg';

const MainHeader = ({ element, goToHomeQuotation }) => {
	const [country, setCountry] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const size = useWindowSize();

	//const onCloseModal = () => setShowModal(false)

	useEffect(() => {
		fetch('https://ipapi.co/json/')
		.then(response => response.json())
		.then(data => {
		  setCountry(data.country_name)
		})
		.catch(error => {
		  console.error('Error:', error);
		});
		//setShowModal(true)
	}, [])
	
	return (
		<header
			className="mt-20 bg-gray-100 md:mt-10 w-full relative flex flex-col md:flex-row"
			ref={element}
			data-testid="mainHeader"
		>
			<style jsx global>
				{`
					@media only screen and (min-width: 1280px) and (max-width: 1390px) {
						.font-responsive {
							font-size: 2.5rem !important;
						}
					}
					@media only screen and (min-width: 960px) and (max-width: 990px) {
						.font-responsive {
							font-size: 1.5rem !important;
						}
					}
				`}
			</style>
			<div
				className="order-1 md:order-2 h-full w-full flex flex-col md:w-2/3 lg:w-2/3 md:z-10 md:absolute bg-cover bg-no-repeat bg-right justify-center"
				style={{ backgroundImage: `url('${sillhoutte}')` }}
			>
				<div className="w-full flex flex-col py-12 px-5 md:px-0 sm:py-16 max-w-90 mx-auto md:max-w-70 md:mx-10 lg:mx-40 lg:max-w-60">
					<h1 className="font-poppins font-bold text-purple-500 text-3xl lg:text-5xl leading-normal md:z-10 font-responsive">
						Look after your loved ones in the Philippines with our accessible health plans
					</h1>
					<p className="text-base md:text-sm lg:text-base font-poppins leading-relaxed text-purple-500 mt-6 md:z-10 lg:w-8/12">
						Protect them from wherever you are against day-to-day ailments, acute illnesses, and medical emergencies with <span className="text-pink-500 font-poppins font-semibold">asistensi</span>.
					</p>
					<div className="flex justify-center md:justify-start mt-8">
						<button
							className="white-btn mr-4"
							onClick={() => goToHomeQuotation()}
							disabled={country === 'United States'}
						>
							<span className="lg:hidden">Quote</span>
							<span className="hidden lg:inline-block">Get your quote</span>
						</button>
						<Link href="/affiliation" as="/start-registration">
							<button className="primary-button ml-2" >
								<span className="sm:hidden">Buy now</span>
								<span className="hidden sm:inline-block">Buy now</span>
							</button>
						</Link>
						{/* {
							showModal && (
								<ModalDisclaimer
									open={showModal}
									handleClose={onCloseModal}
									width={size.width}
								/>
							)
						} */}
					</div>
				</div>
			</div>
			<div className="md:justify-end md:flex w-full">
				<img
					src={bgHeader}
					alt="Insurance in Phillipine"
					name="asistensi-insurance-phillipine.jpg"
					className="md:w-3/5 lg:w-1/2 h-auto md:pl-20 lg:pl-0 md:place-self-end"
				/>
			</div>
		</header>
	);
};

export default MainHeader;
